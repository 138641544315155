<template>
  <div class="media is-vcentered">
    <div class="media-left">
      <figure class="contact-detail-icon is-rounded has-background-primary">
        <slot name="icon"></slot>
      </figure>
    </div>

    <div class="media-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactDetail"
};
</script>

<style lang="scss" scoped>
.contact-detail-icon {
  padding: 11px;
  border-radius: 100px;
  fill: #ffffff;
  width: 48px;
  height: 48px;
}
</style>
